<template>
  <ActionsWidget>
    <div class="mr-2 ml-2">
      <Checkbox
        id="selectedModels"
        v-model="fullSelection"
        :indeterminate="indeterminate"
      />
    </div>
    <div class="flex-1">
      <label for="selectedModels" class="mb-0 whitespace-nowrap">
        <span v-if="selection.mode === 'all'">
          {{ count }} {{ noun }} selected
        </span>
        <span v-else-if="selection.mode === 'all_except' && count">
          {{ count - selection.items.length }} {{ noun }} selected
        </span>
        <span v-else>
          {{ selection.items.length }} selected
          {{ selection.items.length === 1 ? singularNoun : noun }}
        </span>
      </label>
      <label
        v-if="
          selection.mode === 'some' &&
          selection.items.length === items.length &&
          count &&
          items.length < count
        "
        class="mb-0 !text-action-color ml-2"
        @click="handleSelectEverything"
      >
        Select all {{ count }} {{ noun }}
      </label>
    </div>
    <div class="flex space-x-2 overflow-x-auto">
      <slot /></div
  ></ActionsWidget>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

import Checkbox from "@/components/Utilities/Forms/Checkbox.vue";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

import ActionsWidget from "./ActionsWidget.vue";
import { Selection } from "./lib";

export default defineComponent({
  components: { ActionsWidget, Checkbox },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selection: {
      type: Object as PropType<Selection<any>>,
      required: true,
    },
    count: {
      type: Number,
    },
    noun: {
      type: String,
      required: true,
    },
    handleClear: {
      type: Function,
      required: true,
    },
    handleSelectAll: {
      type: Function,
      required: true,
    },
    handleSelectEverything: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
  },
  setup() {
    const scaffoldingStore = useScaffoldingStore();
    const showingSidebar = computed<boolean>(
      () => scaffoldingStore.showingSidebar
    );
    return {
      showingSidebar,
    };
  },
  computed: {
    indeterminate(): boolean {
      return (
        this.selection.mode !== "all" &&
        this.selection.items.length > 0 &&
        this.selection.items.length < this.items.length
      );
    },
    singularNoun(): string {
      return this.noun.substring(0, this.noun.length - 1);
    },
    fullSelection: {
      get(): boolean {
        return (
          this.selection.mode === "all" ||
          this.items.length === this.selection.items.length
        );
      },
      set() {
        if (
          this.selection.mode === "all" ||
          this.selection.items.length === this.items.length
        ) {
          return this.handleClear();
        }
        return this.handleSelectAll();
      },
    },
  },
  methods: {
    toggleSelection() {
      if (
        this.selection.mode === "all" ||
        this.selection.items.length === this.items.length
      ) {
        return this.handleClear();
      }
      return this.handleSelectAll();
    },
  },
});
</script>
