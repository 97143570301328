export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/tmp/build_9e20c395/assets/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/tmp/build_9e20c395/assets/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/analytics',
    name: '/analytics',
    component: () => import('/tmp/build_9e20c395/assets/pages/analytics.vue'),
    children: [
      {
        path: ':id',
        name: '/analytics/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/analytics/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/automations',
    name: '/automations',
    component: () => import('/tmp/build_9e20c395/assets/pages/automations.vue'),
    children: [
      {
        path: ':id',
        name: '/automations/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/automations/[id].vue'),
        children: [
          {
            path: 'delete',
            name: '/automations/[id]/delete',
            component: () => import('/tmp/build_9e20c395/assets/pages/automations/[id]/delete.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/automations/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/automations/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/automations/:id/body',
    name: '/automations.[id].body',
    component: () => import('/tmp/build_9e20c395/assets/pages/automations.[id].body.vue'),
    /* no children */
  },
  {
    path: '/comments',
    name: '/comments',
    component: () => import('/tmp/build_9e20c395/assets/pages/comments.vue'),
    children: [
      {
        path: ':id',
        name: '/comments/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/comments/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/emails',
    name: '/emails',
    component: () => import('/tmp/build_9e20c395/assets/pages/emails.vue'),
    children: [
      {
        path: 'analytics',
        /* internal name: '/emails/analytics' */
        /* no component */
        children: [
          {
            path: ':ids',
            name: '/emails/analytics/[ids]',
            component: () => import('/tmp/build_9e20c395/assets/pages/emails/analytics/[ids].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'imports',
        /* internal name: '/emails/imports' */
        /* no component */
        children: [
          {
            path: 'new',
            name: '/emails/imports/new',
            component: () => import('/tmp/build_9e20c395/assets/pages/emails/imports/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/emails/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/emails/new.vue'),
        /* no children */
      },
      {
        path: 'visibilities',
        /* internal name: '/emails/visibilities' */
        /* no component */
        children: [
          {
            path: ':ids',
            name: '/emails/visibilities/[ids]',
            component: () => import('/tmp/build_9e20c395/assets/pages/emails/visibilities/[ids].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/emails/:id',
    name: '/emails.[id]',
    component: () => import('/tmp/build_9e20c395/assets/pages/emails.[id].vue'),
    children: [
      {
        path: 'completed',
        name: '/emails.[id]/completed',
        component: () => import('/tmp/build_9e20c395/assets/pages/emails.[id]/completed.vue'),
        /* no children */
      },
      {
        path: 'details',
        name: '/emails.[id]/details',
        component: () => import('/tmp/build_9e20c395/assets/pages/emails.[id]/details.vue'),
        /* no children */
      },
      {
        path: 'drafting',
        name: '/emails.[id]/drafting',
        component: () => import('/tmp/build_9e20c395/assets/pages/emails.[id]/drafting.vue'),
        /* no children */
      },
      {
        path: 'history/:historyId?',
        name: '/emails.[id]/history.[[historyId]]',
        component: () => import('/tmp/build_9e20c395/assets/pages/emails.[id]/history.[[historyId]].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/events',
    name: '/events',
    component: () => import('/tmp/build_9e20c395/assets/pages/events.vue'),
    children: [
      {
        path: ':id',
        name: '/events/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/events/[id].vue'),
        /* no children */
      },
      {
        path: 'replay',
        /* internal name: '/events/replay' */
        /* no component */
        children: [
          {
            path: ':ids',
            name: '/events/replay/[ids]',
            component: () => import('/tmp/build_9e20c395/assets/pages/events/replay/[ids].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/feeds',
    name: '/feeds',
    component: () => import('/tmp/build_9e20c395/assets/pages/feeds.vue'),
    children: [
      {
        path: 'new',
        name: '/feeds/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/feeds/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/feeds/:id',
    /* internal name: '/feeds.[id]' */
    /* no component */
    children: [
      {
        path: '',
        name: '/feeds.[id]/',
        component: () => import('/tmp/build_9e20c395/assets/pages/feeds.[id]/index.vue'),
        /* no children */
      },
      {
        path: 'body',
        name: '/feeds.[id]/body',
        component: () => import('/tmp/build_9e20c395/assets/pages/feeds.[id]/body.vue'),
        /* no children */
      },
      {
        path: 'delete',
        name: '/feeds.[id]/delete',
        component: () => import('/tmp/build_9e20c395/assets/pages/feeds.[id]/delete.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/tmp/build_9e20c395/assets/pages/login.vue'),
    /* no children */
  },
  {
    path: '/mentions',
    name: '/mentions',
    component: () => import('/tmp/build_9e20c395/assets/pages/mentions.vue'),
    /* no children */
  },
  {
    path: '/register',
    name: '/register',
    component: () => import('/tmp/build_9e20c395/assets/pages/register.vue'),
    /* no children */
  },
  {
    path: '/requests',
    name: '/requests',
    component: () => import('/tmp/build_9e20c395/assets/pages/requests.vue'),
    children: [
      {
        path: ':id',
        name: '/requests/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/requests/[id].vue'),
        /* no children */
      },
      {
        path: 'api-key',
        /* internal name: '/requests/api-key' */
        /* no component */
        children: [
          {
            path: '',
            name: '/requests/api-key/',
            component: () => import('/tmp/build_9e20c395/assets/pages/requests/api-key/index.vue'),
            /* no children */
          },
          {
            path: 'migrate',
            name: '/requests/api-key/migrate',
            component: () => import('/tmp/build_9e20c395/assets/pages/requests/api-key/migrate.vue'),
            /* no children */
          },
          {
            path: 'regenerate',
            name: '/requests/api-key/regenerate',
            component: () => import('/tmp/build_9e20c395/assets/pages/requests/api-key/regenerate.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/search',
    name: '/search',
    component: () => import('/tmp/build_9e20c395/assets/pages/search.vue'),
    /* no children */
  },
  {
    path: '/settings',
    /* internal name: '/settings' */
    /* no component */
    children: [
      {
        path: '',
        name: '/settings/',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/index.vue'),
        /* no children */
      },
      {
        path: 'basics',
        name: '/settings/basics',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/basics.vue'),
        /* no children */
      },
      {
        path: 'basics/description',
        name: '/settings/basics.description',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/basics.description.vue'),
        /* no children */
      },
      {
        path: 'billing',
        name: '/settings/billing',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/billing.vue'),
        children: [
          {
            path: 'pricing',
            name: '/settings/billing/pricing',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/billing/pricing.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'danger-zone',
        name: '/settings/danger-zone',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/danger-zone.vue'),
        children: [
          {
            path: 'add-newsletter',
            name: '/settings/danger-zone/add-newsletter',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/danger-zone/add-newsletter.vue'),
            /* no children */
          },
          {
            path: 'delete-account',
            name: '/settings/danger-zone/delete-account',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/danger-zone/delete-account.vue'),
            /* no children */
          },
          {
            path: 'delete-newsletter',
            name: '/settings/danger-zone/delete-newsletter',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/danger-zone/delete-newsletter.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'design',
        name: '/settings/design',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/design.vue'),
        /* no children */
      },
      {
        path: 'design/email/footer',
        name: '/settings/design.email.footer',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/design.email.footer.vue'),
        /* no children */
      },
      {
        path: 'design/email/header',
        name: '/settings/design.email.header',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/design.email.header.vue'),
        /* no children */
      },
      {
        path: 'design/web/footer',
        name: '/settings/design.web.footer',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/design.web.footer.vue'),
        /* no children */
      },
      {
        path: 'design/web/header',
        name: '/settings/design.web.header',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/design.web.header.vue'),
        /* no children */
      },
      {
        path: 'domains',
        name: '/settings/domains',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/domains.vue'),
        /* no children */
      },
      {
        path: 'domains/hosting',
        name: '/settings/domains.hosting',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/domains.hosting.vue'),
        /* no children */
      },
      {
        path: 'domains/sending',
        name: '/settings/domains.sending',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/domains.sending.vue'),
        /* no children */
      },
      {
        path: 'embedding',
        name: '/settings/embedding',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/embedding.vue'),
        /* no children */
      },
      {
        path: 'integrations',
        name: '/settings/integrations',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/integrations.vue'),
        children: [
          {
            path: ':id',
            name: '/settings/integrations/[id]',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/integrations/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'notifications',
        name: '/settings/notifications',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/notifications.vue'),
        /* no children */
      },
      {
        path: 'paid-subscriptions',
        name: '/settings/paid-subscriptions',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/paid-subscriptions.vue'),
        children: [
          {
            path: 'disconnect',
            name: '/settings/paid-subscriptions/disconnect',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/paid-subscriptions/disconnect.vue'),
            /* no children */
          },
          {
            path: 'new',
            name: '/settings/paid-subscriptions/new',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/paid-subscriptions/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'profile',
        name: '/settings/profile',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/profile.vue'),
        /* no children */
      },
      {
        path: 'referrals',
        name: '/settings/referrals',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/referrals.vue'),
        /* no children */
      },
      {
        path: 'security',
        name: '/settings/security',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/security.vue'),
        children: [
          {
            path: ':id',
            /* internal name: '/settings/security/[id]' */
            /* no component */
            children: [
              {
                path: 'delete',
                name: '/settings/security/[id]/delete',
                component: () => import('/tmp/build_9e20c395/assets/pages/settings/security/[id]/delete.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'new',
            name: '/settings/security/new',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/security/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'sponsorships',
        name: '/settings/sponsorships',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/sponsorships.vue'),
        /* no children */
      },
      {
        path: 'subscribing',
        name: '/settings/subscribing',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.vue'),
        /* no children */
      },
      {
        path: 'subscribing/confirmation',
        name: '/settings/subscribing.confirmation',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.confirmation.vue'),
        /* no children */
      },
      {
        path: 'subscribing/gift',
        name: '/settings/subscribing.gift',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.gift.vue'),
        children: [
          {
            path: 'drafting',
            name: '/settings/subscribing.gift/drafting',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.gift/drafting.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'subscribing/premium_welcome',
        name: '/settings/subscribing.premium_welcome',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.premium_welcome.vue'),
        children: [
          {
            path: 'drafting',
            name: '/settings/subscribing.premium_welcome/drafting',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.premium_welcome/drafting.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'subscribing/reminder',
        name: '/settings/subscribing.reminder',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.reminder.vue'),
        /* no children */
      },
      {
        path: 'subscribing/welcome',
        name: '/settings/subscribing.welcome',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.welcome.vue'),
        children: [
          {
            path: 'drafting',
            name: '/settings/subscribing.welcome/drafting',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscribing.welcome/drafting.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'subscriptions',
        name: '/settings/subscriptions',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/subscriptions.vue'),
        /* no children */
      },
      {
        path: 'team',
        name: '/settings/team',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/team.vue'),
        children: [
          {
            path: ':id',
            /* internal name: '/settings/team/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/settings/team/[id]/',
                component: () => import('/tmp/build_9e20c395/assets/pages/settings/team/[id]/index.vue'),
                /* no children */
              },
              {
                path: 'delete',
                /* internal name: '/settings/team/[id]/delete' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/settings/team/[id]/delete/',
                    component: () => import('/tmp/build_9e20c395/assets/pages/settings/team/[id]/delete/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'new',
            name: '/settings/team/new',
            component: () => import('/tmp/build_9e20c395/assets/pages/settings/team/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'tracking',
        name: '/settings/tracking',
        component: () => import('/tmp/build_9e20c395/assets/pages/settings/tracking.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/signup',
    name: '/signup',
    component: () => import('/tmp/build_9e20c395/assets/pages/signup.vue'),
    /* no children */
  },
  {
    path: '/sponsorships',
    name: '/sponsorships',
    component: () => import('/tmp/build_9e20c395/assets/pages/sponsorships.vue'),
    children: [
      {
        path: ':id',
        name: '/sponsorships/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/sponsorships/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/sponsorships/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/sponsorships/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/subscribers',
    name: '/subscribers',
    component: () => import('/tmp/build_9e20c395/assets/pages/subscribers.vue'),
    children: [
      {
        path: ':id',
        name: '/subscribers/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/subscribers/[id].vue'),
        children: [
          {
            path: 'change-email-address',
            name: '/subscribers/[id]/change-email-address',
            component: () => import('/tmp/build_9e20c395/assets/pages/subscribers/[id]/change-email-address.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'imports',
        /* internal name: '/subscribers/imports' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/subscribers/imports/[id]',
            component: () => import('/tmp/build_9e20c395/assets/pages/subscribers/imports/[id].vue'),
            /* no children */
          },
          {
            path: 'new',
            name: '/subscribers/imports/new',
            component: () => import('/tmp/build_9e20c395/assets/pages/subscribers/imports/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/subscribers/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/subscribers/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/surveys',
    name: '/surveys',
    component: () => import('/tmp/build_9e20c395/assets/pages/surveys.vue'),
    children: [
      {
        path: ':id',
        name: '/surveys/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/surveys/[id].vue'),
        /* no children */
      },
      {
        path: ':ids',
        /* internal name: '/surveys/[ids]' */
        /* no component */
        children: [
          {
            path: 'delete',
            name: '/surveys/[ids]/delete',
            component: () => import('/tmp/build_9e20c395/assets/pages/surveys/[ids]/delete.vue'),
            /* no children */
          },
          {
            path: 'status',
            name: '/surveys/[ids]/status',
            component: () => import('/tmp/build_9e20c395/assets/pages/surveys/[ids]/status.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/surveys/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/surveys/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/tags',
    name: '/tags',
    component: () => import('/tmp/build_9e20c395/assets/pages/tags.vue'),
    children: [
      {
        path: ':id',
        name: '/tags/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/tags/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/tags/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/tags/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/unsubscription-from-lifecycle-emails-success',
    name: '/unsubscription-from-lifecycle-emails-success',
    component: () => import('/tmp/build_9e20c395/assets/pages/unsubscription-from-lifecycle-emails-success.vue'),
    /* no children */
  },
  {
    path: '/webhooks',
    name: '/webhooks',
    component: () => import('/tmp/build_9e20c395/assets/pages/webhooks.vue'),
    children: [
      {
        path: ':id',
        name: '/webhooks/[id]',
        component: () => import('/tmp/build_9e20c395/assets/pages/webhooks/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/webhooks/new',
        component: () => import('/tmp/build_9e20c395/assets/pages/webhooks/new.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

